<template>
  <el-dialog class="form-dialog" :width="width" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" :before-close="close" v-bind="$attrs" v-on="$listeners">
    <el-form v-if="formItems1" ref="form" :label-width="labelWidth" label-position="left" size="small" :model="formData"
      :disabled="disabled" :rules="formRule" v-loading="loading">
      <el-col :span="12">
        <el-form-item v-for="item in formItems1" :key="item.prop" :label="item.label" :prop="item.prop" :rules="[
          {
            required: item.required || false,
            message: item.errorMessage || '请输入',
            trigger: item.requiredTrigger || 'change'
          },
          ...(item.rules || [])
        ]" v-loading="item.loading">
          <slot v-if="item.slot" :name="item.prop"></slot>
          <template v-else-if="item.type === 'read'">
            {{ item.value ? item.value : formData[item.prop] }}
          </template>
          <template v-else>
            <!-- input -->
            <el-input v-if="item.type === 'input'" v-model="formData[item.prop]" :placeholder="formatPlaceholder(item)"
              :disabled="item.disabled" :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <template v-if="item.append" slot="append">{{ item.append }}</template>
            </el-input>
            <!-- input -->
            <el-input-number v-else-if="item.type === 'inputNumber'" v-model="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :min="item.min" :max="item.max" :step="item.step"
              :precision="item.precision" :controls="item.controls" :disabled="item.disabled"
              :style="{ width: item.width }"></el-input-number>
            <el-input v-else-if="item.type === 'number'" v-model.number="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :disabled="item.disabled" :readonly="item.readonly" clearable
              @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <template slot="append">{{ item.append }}</template>
            </el-input>
            <!-- select -->
            <el-select v-else-if="item.type === 'select'" v-model="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :disabled="item.disabled" :no-data-text="item.noDataText" clearable
              @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <el-option v-for="(selectItem, selectIndex) in item.options"
                :key="`${selectItem[item.optionsValue || 'value']}${selectIndex}`"
                :label="selectItem[item.optionsLabel || 'label']" :value="selectItem[item.optionsValue || 'value']"
                :disabled="selectItem.disabled"></el-option>
            </el-select>
            <!-- textarea -->
            <el-input v-else-if="item.type === 'textarea'" type="textarea" :rows="2"
              :placeholder="formatPlaceholder(item)" v-model="formData[item.prop]" :disabled="item.disabled"
              :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-input>
            <!-- password -->
            <el-input v-else-if="item.type === 'password'" type="password" :rows="2"
              :placeholder="formatPlaceholder(item)" v-model="formData[item.prop]" :disabled="item.disabled"
              :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-input>
            <!-- date -->
            <el-date-picker v-else-if="dateTypes.includes(item.type)" clearable start-placeholder="开始日期"
              end-placeholder="结束日期" range-separator="-" :disabled="item.disabled"
              :value-format="item.valueFormat || dateFormatMap[item.type]"
              :default-time="item.type === 'datetimerange' ? ['00:00:00', '23:59:59'] : item.defaultTime"
              :placeholder="formatPlaceholder(item)" :type="item.type" :picker-options="item.pickerOptions"
              v-model="formData[item.prop]" @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-date-picker>
            <el-radio-group v-else-if="item.type === 'radio'" :placeholder="formatPlaceholder(item)"
              v-model="formData[item.prop]" :disabled="item.disabled" @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <el-radio v-for="(radioItem, index) in item.options" :key="index" :label="radioItem.value"
                :border="item.border">{{
                radioItem.label }}</el-radio>
            </el-radio-group>
            <ld-checkbox-group v-else-if="item.type === 'checkbox'" :options="item.options"
              v-model="formData[item.prop]" :disabled="item.disabled" :checkAllVisible="item.checkAllVisible" />
            <!-- <span v-else>{{ formData[item.prop] || item.value }}</span> -->
          </template>
          <span class="form-item-after-content" :style="item.afterContentStyle" v-if="item.afterContent">
            {{ item.afterContent }}
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item v-for="item in formItems2" :key="item.prop" :label="item.label" :prop="item.prop" :rules="[
          {
            required: item.required || false,
            message: item.errorMessage || '请输入',
            trigger: item.requiredTrigger || 'change'
          },
          ...(item.rules || [])
        ]" v-loading="item.loading">
          <slot v-if="item.slot" :name="item.prop"></slot>
          <template v-else-if="item.type === 'read'">
            {{ item.value ? item.value : formData[item.prop] }}
          </template>
          <template v-else>
            <!-- input -->
            <el-input v-if="item.type === 'input'" v-model="formData[item.prop]" :placeholder="formatPlaceholder(item)"
              :disabled="item.disabled" :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <template v-if="item.append" slot="append">{{ item.append }}</template>
            </el-input>
            <!-- input -->
            <el-input-number v-else-if="item.type === 'inputNumber'" v-model="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :min="item.min" :max="item.max" :step="item.step"
              :precision="item.precision" :controls="item.controls" :disabled="item.disabled"
              :style="{ width: item.width }"></el-input-number>
            <el-input v-else-if="item.type === 'number'" v-model.number="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :disabled="item.disabled" :readonly="item.readonly" clearable
              @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <template slot="append">{{ item.append }}</template>
            </el-input>
            <!-- select -->
            <el-select v-else-if="item.type === 'select'" v-model="formData[item.prop]"
              :placeholder="formatPlaceholder(item)" :disabled="item.disabled" :no-data-text="item.noDataText" clearable
              @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <el-option v-for="(selectItem, selectIndex) in item.options"
                :key="`${selectItem[item.optionsValue || 'value']}${selectIndex}`"
                :label="selectItem[item.optionsLabel || 'label']" :value="selectItem[item.optionsValue || 'value']"
                :disabled="selectItem.disabled"></el-option>
            </el-select>
            <!-- textarea -->
            <el-input v-else-if="item.type === 'textarea'" type="textarea" :rows="2"
              :placeholder="formatPlaceholder(item)" v-model="formData[item.prop]" :disabled="item.disabled"
              :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-input>
            <!-- password -->
            <el-input v-else-if="item.type === 'password'" type="password" :rows="2"
              :placeholder="formatPlaceholder(item)" v-model="formData[item.prop]" :disabled="item.disabled"
              :readonly="item.readonly" clearable @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-input>
            <!-- date -->
            <el-date-picker v-else-if="dateTypes.includes(item.type)" clearable start-placeholder="开始日期"
              end-placeholder="结束日期" range-separator="-" :disabled="item.disabled"
              :value-format="item.valueFormat || dateFormatMap[item.type]"
              :default-time="item.type === 'datetimerange' ? ['00:00:00', '23:59:59'] : item.defaultTime"
              :placeholder="formatPlaceholder(item)" :type="item.type" :picker-options="item.pickerOptions"
              v-model="formData[item.prop]" @change="(val) => {
                  change(item.prop, val);
                }
                ">
            </el-date-picker>
            <el-radio-group v-else-if="item.type === 'radio'" :placeholder="formatPlaceholder(item)"
              v-model="formData[item.prop]" :disabled="item.disabled" @change="(val) => {
                  change(item.prop, val);
                }
                ">
              <el-radio v-for="(radioItem, index) in item.options" :key="index" :label="radioItem.value"
                :border="item.border">{{ radioItem.label }}</el-radio>
            </el-radio-group>
            <ld-checkbox-group v-else-if="item.type === 'checkbox'" :options="item.options"
              v-model="formData[item.prop]" :disabled="item.disabled" :checkAllVisible="item.checkAllVisible" />
            <!-- <span v-else>{{ formData[item.prop] || item.value }}</span> -->
          </template>
          <span class="form-item-after-content" :style="item.afterContentStyle" v-if="item.afterContent">
            {{ item.afterContent }}
          </span>
        </el-form-item>
      </el-col>
    </el-form>
    <slot></slot>
    <template v-slot:footer v-if="footerVisible">
      <slot name="footerLeft"></slot>
      <el-button v-if="cancelVisible" plain size="small" @click="close">{{
        disabled ? "关 闭" : cancelLabel
      }}</el-button>
      <slot name="footerCenter"></slot>
      <el-button v-if="confirmVisible && !disabled" type="primary" size="small" :loading="confirmLoading"
        @click="confirm">{{ confirmLabel }}</el-button>
      <slot name="footerRight"></slot>
    </template>
  </el-dialog>
</template>

<script>
// import FamilyCascader from "../family/FamilyCascader.vue";
// import FamilyTree from "../family/FamilyTree.vue";

export default {
  name: "FormDialog",
  components: {
    // FamilyCascader,
    // FamilyTree
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: "540px"
    },
    labelWidth: {
      type: String,
      default: "110px"
    },
    cancelLabel: {
      type: String,
      default: "取 消"
    },
    confirmLabel: {
      type: String,
      default: "确 定"
    },
    footerVisible: {
      type: Boolean,
      default: true
    },
    cancelVisible: {
      type: Boolean,
      default: true
    },
    confirmVisible: {
      type: Boolean,
      default: true
    },
    confirmLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    formRule: {
      type: Object,
      default: () => ({})
    },
    formItems1: {
      type: [Array, Object],
      default: () => []
    },
    formItems2: {
      type: [Array, Object],
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputTypeList: ["input", "number", "inputNumber", "textarea", "password"],
      dateTypes: [
        "year",
        "month",
        "date",
        "dates",
        "months",
        "years",
        "week",
        "datetime",
        "datetimerange",
        "daterange",
        "monthrange"
      ],
      dateFormatMap: {
        year: "yyyy",
        month: "yyyy-MM",
        date: "yyyy-MM-dd",
        dates: "yyyy-MM-dd",
        months: "yyyy-MM",
        years: "yyyy",
        week: "yyyy-WW",
        datetime: "yyyy-MM-dd HH:mm:ss",
        datetimerange: "yyyy-MM-dd HH:mm:ss",
        daterange: "yyyy-MM-dd",
        monthrange: "yyyy-MM"
      }
    };
  },
  methods: {
    formatPlaceholder(item) {
      return this.disabled ? "" : item.placeholder || `请${this.inputTypeList.includes(item.type) ? "填写" : "选择"}`;
    },
    change(prop, value) {
      this.$emit("change", { prop, value });
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("confirm");
        }
      });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.form-dialog {
  ::v-deep .el-dialog {
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.08), 0px 6px 28px 0px rgba(0, 0, 0, 0.05);
  }

  ::v-deep .el-dialog__header {
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 24px;
  }

  ::v-deep .el-dialog__title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  ::v-deep .el-dialog__body {
    max-height: calc(100vh - 15vh - 55px - 150px);
    padding: 24px 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    overflow: auto;
  }

  ::v-deep .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 51px;
    padding: 0 24px;
  }

  ::v-deep .el-button {
    min-width: 65px;
    padding: 8px 15px;
    font-size: 14px;
  }

  ::v-deep .el-form .el-input {
    width: $input-width;
  }

  ::v-deep .el-form .el-textarea {
    width: $input-width;
  }

  ::v-deep form .ant-select {
    width: $input-width;
  }

  ::v-deep .el-input-number .el-input {
    width: auto;
  }

  ::v-deep .el-radio {
    margin-bottom: 10px;
  }

  .form-item-after-content {
    margin-left: 10px;
  }
}
</style>
